.wrap_gold {
  position: absolute;
  top: 0;
  left: 260px;
  width: 150px;
  height: 37px;
  z-index: 4;
  background: url('/images/ui/main/bg_gold.png');

  .gold {
    position: absolute;
    left: 38px;
    top: 6px;
    width: 29px;
    height: 26px;
    font-size: 18px;
    color: #ffad12;
  }

  .plus {
    position: absolute;
    right: 1px;
    top: 6px;
    width: 29px;
    height: 26px;
    cursor: pointer;
    background: url('/images/ui/main/plus.png');

    &:hover {
      opacity: .7;
    }
  }
}

.wrap_life {
  position: absolute;
  top: 0;
  left: 440px;
  width: 240px;
  height: 37px;
  z-index: 4;
  background: url('/images/ui/main/bg_life.png');

  .timerLife {
    position: absolute;
    width: 60px;
    height: 19px;
    top: 37px;
    right: 23px;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    padding-top: 1px;
    border-radius: 0 0 8px 8px;
    overflow: hidden;
    color: #ffcb38;
    background: #775029;
    font-family: Tahoma, Arial, Verdana, sans-serif;

    &.hide {
      height: 0;
    }
  }

  .item_life {
    position: absolute;
    top: 4px;
    left: 14px;
    width: 30px;
    height: 28px;
    background: url('/images/ui/main/life.png');
  }

  .plus {
    position: absolute;
    right: 7px;
    top: 6px;
    width: 29px;
    height: 26px;
    cursor: pointer;
    background: url('/images/ui/main/plus.png');

    &:hover {
      opacity: .7;
    }
  }
}

.wrap_settings {
  position: absolute;
  top: 0;
  left: 708px;
  width: 150px;
  height: 37px;
  z-index: 4;
  background: url('/images/ui/main/bg_settings.png');
}
.messages {
  position: absolute;
  left: 723px;
  top: 2px;
  font-size: 17px;
  padding: 2px 5px;
  cursor: pointer;
  color: #f59f70;
  z-index: 5;

  &:hover {
    color: #da896b;
  }

  .counter_messages {
    position: absolute;
    top: -2px;
    right: -6px;
    font-size: 11px;
    color: #fff;
  }
}

.info {
  position: absolute;
  left: 776px;
  top: 3px;
  font-size: 19px;
  padding: 2px 5px;
  cursor: pointer;
  color: #f59f70;
  z-index: 5;

  &:hover {
    color: #da896b;
  }
}

.sound {
  position: absolute;
  left: 816px;
  top: 0px;
  font-size: 21px;
  padding: 2px 5px;
  cursor: pointer;
  color: #f59f70;
  z-index: 5;

  &:hover {
    color: #da896b;
  }
}
.sound_disabled {
  position: absolute;
  left: 820px;
  top: -2px;
  font-size: 26px;
  opacity: 0.6;
  cursor: pointer;
  color: #fdfdfd;
  z-index: 6;
}



.information {
  padding: 8px;
  border-radius: 10px;
  border: 3px solid #9e4f0d;
  color: #9e3409;
  background-color: #e68644;
}

.m_messages_wrap {
  position: relative;
  display: flex;
  align-items: center;
  height: 78px;
  margin: 10px 6px;
  border-radius: 10px;
  border: 2px solid #cb9a67;
  background-color: #ecd4bb;

  .remove_messages {
    position: absolute;
    top: -14px;
    right: -3px;
    width: 20px;
    height: 20px;
    font-size: 24px;
    padding: 0px 4px;
    cursor: pointer;
    color: #dc4444;
  }

  .avatar {
    position: relative;
    width: 50px;
    height: 50px;
    margin: 0 30px 0 30px;
    border-radius: 4px;
    border: 2px solid #bb6427;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 46px;
      height: 46px;
      border-radius: 2px;
    }
  }

  .text {
    width: 200px;
    color: #a5470e;

    .name {
      font-size: 12px;
      color: #f46f11;
    }
  }

  .img {
    display: flex;
    align-items: center;
    width: 50px;
    height: 50px;
    margin-right: 30px;
  }

  .button {
    width: 130px;
    height: 34px;
    padding: 6px 0;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    border-radius: 10px;
    color: #fff;
    background-color: #52af32;

    &:hover {
      background-color: #5bc237;
    }
  }
}

.ask_friends {
  padding-top: 10px;

  .img_block {
    position: relative;
    text-align: center;

    .img_wrap {
      display: inline-block;
      width: 340px;
      padding: 12px;
      cursor: pointer;
      border-radius: 10px;
      border: 3px solid rgba(255, 255, 255, 0.2);
      background-color: rgba(0, 0, 0, 0.3);

      img {
        border-radius: 6px;
      }
    }
  }

  .input_wrap {
    font: 15px Marvin, Tamburin, Tahoma, Arial, Helvetica, sans-serif;
    position: relative;
    left: 50%;
    top: 10px;
    width: 340px;
    margin-left: -170px;
    padding: 10px;
    text-align: center;
    border-radius: 6px;
    border: 2px solid #9ae095;
    color: #fff;
    background-color: #54841c;
  }

  input[type="text"]::placeholder { color: #9eff88; }

  .input_button {
    font: 15px Marvin, Tamburin, Tahoma, Arial, Helvetica, sans-serif;
    position: relative;
    left: 50%;
    top: 20px;
    width: 340px;
    margin-left: -170px;
    padding: 10px;
    text-align: center;
    border-radius: 6px;
    cursor: pointer;
    border: 2px solid #799ecf;
    color: #fff;
    background-color: #3f76cf;
  }
}


.user_info_wrap {
  position: absolute;
  top: 0;
  left: 50px;
  width: 186px;
  height: 57px;
  z-index: 4;
  background: url('/images/ui/main/user_info_1.png');

  .wrap_img {
    position: absolute;
    top: 4px;
    left: 12px;
    width: 46px;
    height: 46px;
    border-radius: 50%;

    img {
      border-radius: 50%;
    }
  }

  .text_level {
    position: absolute;
    top: 1px;
    left: 68px;
    width: 90px;
    font-size: 19px;
    text-align: center;
  }

  .text_answers {
    position: absolute;
    top: 31px;
    left: 102px;
    width: 66px;
    font-size: 13px;
    text-align: center;
  }
}