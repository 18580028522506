#field_game {
  position: relative;
  height: 600px;
  overflow: hidden;
  background: url('/images/ui/game/bg_game_1.jpg') 0 0 #505050;

  .leave_game {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 6px 10px;
    cursor: pointer;
    border-radius: 6px 10px;
    background: linear-gradient(#656565, #3c3c3c) #656565;
  }

  .hints {
    position: absolute;
    left: 50px;
    top: 52px;
    width: 186px;
    height: 281px;
    z-index: 2;
    background: url('/images/ui/game/info_left.png') transparent !important;

    .hints_all {
      position: absolute;
      left: 0;
      width: 186px;
      padding: 16px 10px;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      cursor: pointer;
      border-radius: 14px;
      color: #fff;
    }

    .add_letter {
      top: 10px;
    }

    .remove_excess {
      top: 84px;
    }

    .skip {
      top: 158px;
    }

    .ask_friends {
      top: 230px;
    }

    .add_letter:hover,
    .remove_excess:hover,
    .skip:hover,
    .ask_friends:hover {
      box-shadow: 0 0 3px 3px #439a2c;
    }

    .minus_gold {
      position: absolute;
      bottom: -14px;
      left: -30px;
      width: 60px;
      height: 28px;
      padding: 2px 4px 0 0;
      text-align: right;
      border-radius: 6px;
      border: 2px solid #c16f35;
      background-color: #7d2a00;

      .coin {
        position: absolute;
        top: 2px;
        left: 4px;
        width: 18px;
        height: 18px;
      }
    }
  }

  .img_block {
    position: relative;
    margin-top: 60px;
    text-align: center;

    .img_wrap {
      display: inline-block;
      width: 290px;
      padding: 12px;
      cursor: pointer;
      border-radius: 10px;
      border: 3px solid rgba(255, 255, 255, 0.2);
      background-color: rgba(0, 0, 0, 0.3);

      img {
        border-radius: 6px;
      }
    }
  }

  .info_right {
    position: absolute;
    right: 50px;
    top: 60px;
    width: 186px;
    height: 271px;
    z-index: 2;
    background: url('/images/ui/game/info_right.png') transparent !important;

    .level_info {
      position: absolute;
      top: 8px;
      width: 186px;
      font-size: 18px;
      text-align: center;

      .number {
        position: absolute;
        bottom: -40px;
        width: 186px;
        font-size: 23px;
        text-align: center;
        color: #ffca00;
      }
    }

    .answers_info {
      position: absolute;
      top: 106px;
      width: 186px;
      font-size: 18px;
      text-align: center;

      .number {
        position: absolute;
        bottom: -40px;
        width: 186px;
        font-size: 23px;
        text-align: center;
        color: #ffca00;
      }
    }

    .letters_info {
      position: absolute;
      top: 204px;
      width: 186px;
      font-size: 17px;
      text-align: center;

      .number {
        position: absolute;
        bottom: -40px;
        width: 186px;
        font-size: 23px;
        text-align: center;
        color: #ffca00;
      }
    }
  }

  .letters_active_block {
    text-align: center;
    margin-top: 18px;

    .letters_active {
      position: relative;
      display: inline-block;
      max-width: 96%;
      margin: 0 auto;
      padding: 10px;
      border-radius: 10px;
      border: 3px solid rgba(255, 255, 255, 0.2);
      background-color: rgba(0, 0, 0, 0.3);

      .item_wrap {
        display: inline-block;
        width: 28px;
        height: 28px;
        margin: 2px;
        border-radius: 4px;
        border: 2px solid rgba(0, 0, 0, 0.1);
        background-color: rgba(0, 0, 0, 0.2);

        .item {
          padding: 1px 0;
          font-size: 16px;
          font-weight: bold;
          cursor: pointer;
          border-radius: 2px;
          color: #fff;
          background-color: #349e54;
        }

        .item_null {
          cursor: auto;
          background-color: transparent;
        }
      }
    }
  }

  .letters_wrap_block {
    text-align: center;
    margin-top: 18px;

    .letters_wrap {
      position: relative;
      display: inline-block;
      max-width: 96%;
      text-align: center;
      padding: 10px;
      border-radius: 10px;
      border: 3px solid rgba(255, 255, 255, 0.2);
      background-color: rgba(0, 0, 0, 0.3);

      .item_wrap {
        display: inline-block;
        width: 40px;
        height: 40px;
        margin: 2px;
        font-size: 20px;
        font-weight: bold;
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.3);

        .item {
          position: relative;
          left: 5px;
          top: 5px;
          width: 30px;
          height: 30px;
          padding: 2px 0;
          cursor: pointer;
          color: #fff;
          background: url('/images/ui/game/item_letter.png') 0 0 #467bcf;
        }

        .empty_item {
          padding: 2px 0;
          color: transparent;
        }
      }
    }
  }
}