.bottomSave {
  position: absolute;
  left: 10px;
  bottom: 10px;
  padding: 5px 10px;
  border-radius: 6px;
  background: #0eaed2;
}

.addLevelsInMap {
  position: absolute;
  left: 130px;
  bottom: 10px;
  width: 70px;
  padding: 4px;
  border-radius: 6px;
  border: 2px solid #fff;
}

.bottomAddLevelsInMap {
  position: absolute;
  left: 210px;
  bottom: 10px;
  padding: 5px;
  border-radius: 6px;
  background: #0eaed2;
}

.selectIdMap {
  position: absolute;
  left: 300px;
  bottom: 10px;
  width: 70px;
  padding: 4px;
  border-radius: 6px;
  border: 2px solid #fff;
}