.everyday {
  padding-top: 10px;
  text-align: center;

  .item {
    position: relative;
    display: inline-block;
    width: 129px;
    height: 172px;
    background: url('/images/ui/main/everyday/d5.png');

    .title {
      font-size: 18px;
      padding: 8px 19px 4px 0;
      color: #a25913;
    }

    .gold {
      position: absolute;
      left: 20px;
      bottom: 11px;
      width: 70px;
      font-size: 18px;
      color: #fff;
    }
  }

  .item_no {
    position: relative;
    display: inline-block;
    width: 129px;
    height: 172px;
    background: url('/images/ui/main/everyday/d5_no.png');

    .title {
      font-size: 18px;
      padding: 8px 19px 4px 0;
      color: #676767;
    }

    .gold {
      position: absolute;
      left: 20px;
      bottom: 11px;
      width: 70px;
      font-size: 18px;
      color: #a5a5a5;
    }
  }

  .button_get {
    position: absolute;
    left: 100px;
    bottom: 30px;
    padding: 10px 20px;
    text-align: center;
    font-size: 15px;
    cursor: pointer;
    border-radius: 4px;
    border: 2px solid #588222;
    color: #fff;
    background: linear-gradient(#85c532, #538e07) #538e07;
  }

  .tell_friends {
    position: absolute;
    right: 70px;
    bottom: 30px;
    font-size: 15px;
    padding: 10px 10px 10px 40px;
    color: #fff;

    .checkbox {
      position: absolute;
      left: 2px;
      bottom: 8px;
      width: 24px;
      height: 24px;
      border-radius: 3px;
      cursor: pointer;
      background-color: #ff8200;

      .is_active {
        position: absolute;
        left: 5px;
        bottom: 5px;
        width: 14px;
        height: 14px;
        border-radius: 3px;
        box-shadow: 0px 0px 9px #e0e0e0 inset;
        background-color: #ffab09;
      }
    }
  }
}