@font-face {
  font-family: 'Tamburin';
  src: url('/fonts/Tamburin/TamburinModern.eot?#iefix') format('embedded-opentype'),  url('/fonts/Tamburin/TamburinModern.woff') format('woff'), url('/fonts/Tamburin/TamburinModern.ttf')  format('truetype'), url('/fonts/Tamburin/TamburinModern.svg#TamburinModern') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Marvin';
  src: url('/fonts/Marvin/Marvin.eot?#iefix') format('embedded-opentype'),  url('/fonts/Marvin/Marvin.otf')  format('opentype'),
  url('/fonts/Marvin/Marvin.woff') format('woff'), url('/fonts/Marvin/Marvin.ttf')  format('truetype'), url('/fonts/Marvin/Marvin.svg#Marvin') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: AntDesign;
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/AntDesign.ttf) format('truetype')
}
@font-face {
  font-family: Entypo;
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/Entypo.ttf) format('truetype')
}
@font-face {
  font-family: EvilIcons;
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/EvilIcons.ttf) format('truetype')
}
@font-face {
  font-family: Feather;
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/Feather.ttf) format('truetype')
}
@font-face {
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/FontAwesome.ttf) format('truetype')
}
//@font-face {
//  font-family: FontAwesome5;
//  font-style: normal;
//  font-weight: 400;
//  src: url(/fonts/FontAwesome5.ttf) format('truetype')
//}
//@font-face {
//  font-family: FontAwesome5Brands;
//  font-style: normal;
//  font-weight: 400;
//  src: url(/fonts/FontAwesome5_Brands.ttf) format('truetype')
//}
@font-face {
  font-family: Foundation;
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/Foundation.ttf) format('truetype')
}
@font-face {
  font-family: Ionicons;
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/Ionicons.ttf) format('truetype')
}
@font-face {
  font-family: MaterialCommunityIcons;
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/MaterialCommunityIcons.ttf) format('truetype')
}
@font-face {
  font-family: MaterialIcons;
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/MaterialIcons.ttf) format('truetype')
}
@font-face {
  font-family: Octicons;
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/Octicons.ttf) format('truetype')
}
@font-face {
  font-family: SimpleLineIcons;
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/SimpleLineIcons.ttf) format('truetype')
}
@font-face {
  font-family: Zocial;
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/Zocial.ttf) format('truetype')
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  vertical-align: baseline;
  user-select: none;
}

a {
  text-decoration: none;
  color: #2B587A;
}

html, body {
  font: 13px Marvin, Tamburin, Tahoma, Arial, Helvetica, sans-serif;
  color: #fff;
}


img {
  max-width: 100%;
  height: auto;
  pointer-events: none;
}

img.center {
  display: block;
  margin: 0 auto;
}

#wrapper, #game {
  position: relative;
  width: 900px;
  margin: 0 auto;
}

#game {
  height: 600px;
  overflow: hidden;
}


#age_restriction {
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 7px 12px;
  font-size: 26px;
  border-radius: 6px;
  background-color: #9c5656;
}

#word_load {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -30px 0 0 -50px;
  font-size: 16px;
  color: #7d7d7d;
}


#preload_wrap {
  position: absolute;
  top: 330px;
  left: 50%;
  margin-left: -180px;
  border-radius: 6px;
  border: 3px solid #d4ebff;
  background: linear-gradient(#4bbd2d, #33841d) #33841d;

  #line_bot {
    position: relative;
    width: 340px;
    height: 30px;
    margin: 10px;
    border-radius: 6px;
    background: linear-gradient(#2cc0d6, #95e1ec) #2cc0d6;

    #percent {
      position: absolute;
      top: 3px;
      left: 50%;
      margin-left: -10px;
      font-size: 18px;
      color: #fff;
    }

    #line_top {
      width: 0;
      height: 30px;
      border-radius: 6px;
      background: linear-gradient(#ffb822, #ffd06d) #ffd06d;
    }
  }
}


.button_global_wrap {
  margin-top: 20px;
  width: 100%;
  text-align: center;

  .button {
    display: inline-block;
    margin: 0 10px;
    padding: 10px 18px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 6px;
    color: #fff;

    &:hover {
      box-shadow: 2px 2px 0 0 #388425;
    }
  }

  .button_one {
    background-color: #616161;
  }

  .button_two {
    border: 2px solid #388425;
    background: linear-gradient(#64b753, #4c9044) #4c9044;
  }
}


.thumb_v {
  cursor: pointer;
  border-radius: 3px;
  background-color: #f46f11;
}



.fl_l {
  float: left;
}

.fl_r {
  float: right;
}

.clear_fix {
  display: block;
}

.clear_fix:after {
  content: ".";
  clear: both;
  display: block;
  height: 0;
  visibility: hidden;
}


.maskSpinner {
  background-color: #000;
  position: absolute;
  left: auto;
  top: 0;
  width: 900px;
  height: 600px;
  opacity: 0.1;
  z-index: 104;
}
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  z-index: 105;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.6);

  .img {
    background: url("/images/spinner.png");
    width: 80px;
    height: 80px;
    background-size: cover;
    animation: spinnerAnimation 2s linear infinite;
  }
}
@keyframes spinnerAnimation { 100% {transform:rotate(360deg); } }