$width: 900px;

.button_repeated {
  display: inline-block;
  width: 220px;
  margin-top: 40px;
  padding: 10px 4px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 6px;
  border: 2px solid #ffc98c;
  background: linear-gradient(#e4892b, #c37524) #c37524;

  &:hover {
    background: linear-gradient(#f3912c, #da8925) #da8925;
  }
}

.admin {
  position: absolute;
  left: 2px;
  top: 2px;
  width: 30px;
  height: 30px;
  z-index: 100;
  background: #0eaed2;
}

.main {
  position: relative;
  height: 600px;
  overflow: hidden;
  background: #da8925;

  .arrows_map {
    position: absolute;
    top: 286px;
    width: 37px;
    height: 28px;
    opacity: 0.5;
    cursor: pointer;
    z-index: 3;

    &.left {
      left: 5px;
      background: url('/images/ui/main/arrow_map_left.png') no-repeat 0 0 transparent;
    }

    &.right {
      right: 5px;
      background: url('/images/ui/main/arrow_map_right.png') no-repeat 0 0 transparent;
    }

    &:hover {
      opacity: 1;
    }
  }

  .avatar_wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 36px;
    height: 36px;
    overflow: hidden;
    z-index: 3;
    border-radius: 4px;
    border: 2px solid #fff;
    background-size: cover;
  }

  .level_animation {
    position: absolute;
    left: 0;
    top: 0;
    width: 33px;
    height: 65px;
    transform-origin: bottom;
    animation: level_animation 1s linear 1ms infinite;
    z-index: 3;
    background: url('/images/ui/main/arrow_level.png') no-repeat 0 0 transparent;
  }

  @keyframes level_animation {
    0% {
      transform: translateY(0) scaleY(1);
      transform-origin: 0 100%;
    }
    16.666% {
      transform: translateY(-5px) scaleY(1);
      transform-origin: 0 0;
    }
    33.333% {
      transform: translateY(-5px) scaleY(0.95);
      transform-origin: 0 0;
    }
    50% {
      transform: translateY(-5px) scaleY(1);
      transform-origin: 0 0;
    }
    66.666% {
      transform: translateY(0) scaleY(1);
      transform-origin: 0 100%;
    }
    83.333% {
      transform: translateY(0) scaleY(0.95);
      transform-origin: 0 100%;
    }
    100% {
      transform: translateY(0) scaleY(1);
      transform-origin: 0 100%;
    }
  }

  .item_map {
    position: absolute;
    top: 0;
    left: 0;
    width: 54px;
    height: 35px;
    padding: 2px 2px;
    z-index: 2;
    color: #fff;
    background: url('/images/ui/main/level_map.png');

    &.level_pass {
      background: url('/images/ui/main/level_map_pass.png');
    }

    .text {
      width: 50px;
      margin-left: -5px;
      text-align: center;
      font-size: 15px;
    }
  }

  .mask_level {
    position: absolute;
    left: 0;
    top: 0;
    width: 46px;
    height: 49px;
    cursor: pointer;
    background-color: transparent;
  }

  .wrap_friends {
    position: absolute;
    bottom: 0;
    left: ($width - 722px) / 2;
    width: 722px;
    height: 109px;
    z-index: 4;
    background: url('/images/ui/main/bg_friends.png');

    .menuFriendWrap {
      position: absolute;
      left: 145px;
      bottom: 107px;
      width: 430px;
      z-index: 6;

      .topBlock {
        position: absolute;
        top: -5px;
        left: 130px;
        width: 170px;
        height: 10px;
        z-index: 1;
        border-radius: 4px;
        box-shadow: 0 0 6px 3px #247cff;
        background: #23b8ff;
      }

      .menuFriend {
        position: relative;
        display: flex;
        //align-items: center;
        justify-content: center;
        width: 430px;
        height: 0;
        padding: 0;
        overflow: hidden;
        transition: height 0.2s, padding 0.2s;
        border-radius: 40px 40px 0 0;
        border-bottom: none;
        background: #ffba53;

        &.animationF {
          height: 110px;
          padding-top: 32px;
        }

        .userName {
          position: absolute;
          left: 0;
          top: 12px;
          width: 100%;
          text-align: center;
        }

        .itemWrap {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 100px;
          margin: 0 10px;
          cursor: pointer;

          .blockItem {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 5px;
            border: 1px solid #ff5e00;
            background: #da8925;

            img {
              border-radius: 5px;
            }
          }

          .textItem {
            font-size: 12px;
            text-align: center;
            text-decoration: none;
            color: #ec5a00;
          }
        }
      }
    }

    .arrows {
      position: absolute;
      bottom: 2px;
      width: 45px;
      height: 39px;
      cursor: pointer;
      z-index: 3;

      &.arrow_left {
        left: 50px;
        z-index: 2;
        background: url('/images/ui/main/arrow_left.png');
      }

      &.arrow_right {
        right: 50px;
        z-index: 2;
        background: url('/images/ui/main/arrow_right.png');
      }
    }

    .item_friend {
      position: absolute;
      left: 110px;
      bottom: 0;
      width: 102px;
      height: 92px;
      padding: 60px 4px 3px 4px;
      background: url('/images/ui/main/wrap_friend.png');

      .avatar {
        position: absolute;
        left: 31px;
        top: 16px;
        width: 40px;
        height: 40px;
        border-radius: 4px;
        overflow: hidden;
        cursor: pointer;
      }

      .name {
        font-size: 12px;
        text-align: center;
        text-shadow: 2px 2px 3px rgba(0, 0, 0, .5);
        color: #bfde31;
      }

      .level_friend {
        margin-top: -4px;
        font-size: 14px;
        text-align: center;
        text-shadow: 2px 2px 3px rgba(0, 0, 0, .7);
        color: #ffca00;
      }
    }
  }
}