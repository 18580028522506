.m_mask {
  background-color: #000;
  position: absolute;
  left: auto;
  top: 0;
  width: 900px;
  height: 600px;
  opacity: 0.4;
  z-index: 100;
}

.m_modal_wrap {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 100;
}

.m_global {
  position: absolute;
  padding: 56px 40px 40px 40px;
  opacity: 1;
  z-index: 100;

  .top_left {
    position: absolute;
    top: 0;
    left: 0;
    width: 54px;
    height: 64px;
    background: url("/images/ui/modal/top_left.png") 0 0 no-repeat;
  }

  .top_right {
    position: absolute;
    top: 0;
    right: 0;
    width: 54px;
    height: 64px;
    background: url("/images/ui/modal/top_right.png") 0 0 no-repeat;
  }

  .top_center {
    position: absolute;
    top: 0;
    right: 20px;
    left: 20px;
    width: inherit;
    height: 64px;
    background: url("/images/ui/modal/top_center.png") 0 0 repeat-x;
  }

  .bot_left {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 54px;
    height: 46px;
    background: url("/images/ui/modal/bot_left.png") 0 0 no-repeat;
  }

  .bot_right {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 54px;
    height: 46px;
    background: url("/images/ui/modal/bot_right.png") 0 0 no-repeat;
  }

  .bot_center {
    position: absolute;
    bottom: 0;
    right: 20px;
    left: 20px;
    width: inherit;
    height: 46px;
    background: url("/images/ui/modal/bot_center.png") 0 0 repeat-x;
  }

  .left {
    position: absolute;
    top: 64px;
    left: 19px;
    bottom: 46px;
    width: 35px;
    background: url("/images/ui/modal/left.png") 0 0 repeat-y;
  }

  .right {
    position: absolute;
    top: 64px;
    right: 19px;
    bottom: 46px;
    width: 35px;
    background: url("/images/ui/modal/right.png") 0 0 repeat-y;
  }

  .m_title {
    position: absolute;
    top: 5px;
    left: 50%;
    margin-left: -150px;
    width: 314px;
    height: 44px;
    text-align: center;
    padding: 11px 2px;
    font-size: 14px;
    z-index: 3;
    color: #fff;
    background: url("/images/ui/modal/title.png") 0 0 no-repeat;
  }

  .m_close {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 46px;
    height: 46px;
    cursor: pointer;
    background: url("/images/ui/modal/close.png") 0 0 no-repeat;

    &:hover {
      opacity: .9;
    }
  }

  .m_content {
    position: relative;
    width: 600px;
    height: 400px;
    background: url("/images/ui/modal/center.png") 0 0 repeat;

    .error_message {
      position: absolute;
      top: 50%;
      width: 100%;
      padding: 10px;
      text-align: center;
      font-size: 20px;
      transform: translateY(-50%);
    }
  }
}