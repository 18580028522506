.buy_gold_wrap {
  position: relative;

  .one {
    position: absolute;
    top: 14px;
    left: 22px;
    width: 560px;
    height: 147px;
    cursor: pointer;
    transition: transform 0.5s ease;
    background: url('/images/ui/buy_gold/1.png') no-repeat;

    &:hover {
      transform: scale(1.02);
    }

    .gold {
      position: absolute;
      top: 16px;
      left: 41%;
      font-size: 37px;
      color: #fff;
      text-shadow: 3px 4px 7px #193b4a;
    }

    .price {
      position: absolute;
      bottom: 12px;
      left: 44%;
      text-align: center;
      font-size: 15px;
      color: #fff;
      text-shadow: 1px 1px 4px #193b4a;
    }
  }

  .other {
    position: absolute;
    top: 194px;
    width: 174px;
    height: 144px;
    cursor: pointer;
    transition: transform 0.5s ease;

    &.item_1 {
      left: 30px;
      background: url('/images/ui/buy_gold/2.png') no-repeat;
    }
    &.item_2 {
      left: 217px;
      background: url('/images/ui/buy_gold/3.png') no-repeat;
    }
    &.item_3 {
      left: 402px;
      background: url('/images/ui/buy_gold/4.png') no-repeat;
    }

    .gold {
      position: absolute;
      top: 33px;
      left: 50%;
      width: 100px;
      margin-left: -50px;
      text-align: center;
      font-size: 30px;
      color: #fff;
      text-shadow: 2px 2px 3px #193b4a;
    }

    .price {
      position: absolute;
      top: 10px;
      left: 50%;
      width: 100px;
      margin-left: -50px;
      text-align: center;
      font-size: 14px;
      color: #fff;
      text-shadow: 1px 1px 4px #193b4a;
    }

    &:hover {
      transform: scale(1.02);
    }
  }
}