.addAnswerWrapper {
  display: flex;

  .leftBlock {
    width: 50%;
    background: #4bbd2d;

    .itemWrap {
      padding: 3px 10px;
      cursor: pointer;
      border-bottom: 1px solid #e7dfe1;
      background: #2B587A;

      &:hover {
        opacity: 0.9;
      }
    }
  }

  .rightBlock {
    width: 50%;
    height: 600px;
    overflow-y: scroll;
    background: #bd3641;

    .inputSearch {
      width: 100%;
      padding: 4px;
      margin: 4px 0;
    }

    .buttonSearch {
      padding: 4px 6px;
      background: #0eaed2;
      color: #fff;
    }

    .itemWrap {

      .img {
        width: 100px;
        height: 100px;
      }
    }
  }
}