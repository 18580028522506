.chibiWrap {
  position: absolute;
  right: 20px;
  bottom: 8px;
  font-size: 17px;
  padding: 2px 5px;
  cursor: pointer;
  color: #f59f70;
  z-index: 5;

  .chibiIcon {
    width: 70px;

    &:hover {
      opacity: 0.8;
    }
  }

  .chibiText {
    font-size: 14px;
    text-align: center;
    color: #fff;
  }
}

.chibiModal {
  position: relative;

  .board {
    display: flex;
    width: 696px;
    height: 222px;
    margin: 0 0 0 2px;
    background: url('/images/ui/chibi/board.png');

    .itemChibi {
      position: relative;
      width: 150px;
      height: 150px;
      margin: 6px 0 0 14px;

      &:first-child {
        margin-left: 27px;
      }

      &:last-child {
        margin-right: 27px;
      }

      .improved {
        position: absolute;
        bottom: 23px;
        width: 7px;
        height: 64px;
        background: #23b8ff;
        border-radius: 50%;
        box-shadow: 0 0 6px 3px #247cff;

        &.right {
          right: 6px;
        }

        &.left {
          left: 6px;
        }
      }

      .itemTitle {
        position: absolute;
        left: 10px;
        bottom: -31px;
        width: 130px;
        height: 26px;
        padding: 4px 4px;
        overflow: hidden;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #fff;
      }

      .checkBox {
        position: absolute;
        right: -1px;
        top: -3px;
        width: 30px;
        height: 30px;
        text-align: center;
        border-radius: 8px;
        border: 2px solid #098db5;
        background: #0eaed2;
      }
    }
  }

  .buttonsWrap {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 10px;
    right: 10px;
    bottom: -45px;

    .button {
      margin: 0 10px;
      padding: 8px 18px;
      font-size: 16px;
      cursor: pointer;
      border-radius: 6px;
      color: #fff;
      background: #e26a08;

      &:hover {
        box-shadow: 2px 2px 0 0 #9c4e08;
      }
    }
  }
}