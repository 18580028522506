.buy_life_wrap {
  position: relative;

  .chibi {
    position: absolute;
    left: 0px;
    top: 10px;
    width: 191px;
    height: 236px;
    background: url('/images/ui/buy_life/chibi.png') no-repeat;
  }

  .ask_friends {
    position: absolute;
    top: 288px;
    left: 0px;
    width: 190px;
    height: 50px;
    padding: 12px 2px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    border-radius: 10px;
    border: 2px solid #528b08;
    background: linear-gradient(#87da1c, #70af1e) #4c9044;
  }

  .block_items {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 360px;
    padding: 6px 14px;
    border-radius: 24px;
    border: 3px solid #c97f31;
    background-color: #e59a4b;

    .item {
      position: relative;
      padding: 15px 10px;
      margin: 4px 0;
      font-size: 18px;
      border-radius: 10px;
      border: 2px solid #fdca94;
      background-color: #df6c11;

      .button_buy {
        position: absolute;
        top: 8px;
        right: 10px;
        padding: 5px 12px;
        cursor: pointer;
        border-radius: 8px;
        border: 2px solid #528b08;
        background: linear-gradient(#87da1c, #70af1e) #4c9044;

        &:hover {
          background: linear-gradient(#92eb1e, #7fc722) #7fc722;
        }

        &.not_active {
          border: 2px solid #737373;
          background: linear-gradient(#969696, #6e7960) #6e7960;
        }
      }

      .count_heart {
        position: absolute;
        top: 13px;
        left: 20px;
        width: 40px;
        text-align: right;
        padding: 2px 8px;
        border-radius: 0 4px 4px 0;
        background-color: #ad530c;

        .heart {
          position: absolute;
          top: -1px;
          left: -13px;
        }
      }

      .text_gold {
        margin-left: 86px;
        width: 56px;
      }

      .coin {
        position: absolute;
        top: 14px;
        left: 150px;
        width: 24px;
        height: 29px;
        background: url('/images/ui/buy_life/coin.png') no-repeat;
      }
    }
  }
}


.life_ask_friends {
  position: relative;
  width: 48%;
  height: 78px;
  margin: 4px 6px;
  border-radius: 10px;
  border: 2px solid #cb9a67;
  background-color: #ecd4bb;

  .avatar {
    position: absolute;
    top: 11px;
    left: 11px;
    width: 50px;
    height: 50px;
    border-radius: 4px;
    border: 2px solid #bb6427;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 46px;
      height: 46px;
      border-radius: 2px;
    }
  }

  .name {
    width: 142px;
    margin: 17px 0 0 70px;
    font-size: 13px;
    color: #f46f11;
  }

  .button {
    position: absolute;
    top: 20px;
    right: 10px;
    width: 100px;
    height: 34px;
    padding: 6px 0;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    border-radius: 10px;
    color: #fff;
    background-color: #52af32;

    &:hover {
      background-color: #5bc237;
    }
  }
}